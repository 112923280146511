<template>
  <v-app>
    <v-container fluid>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-row class="justify-center">
          <v-col cols="2" class="pa-1">
            <span> Họ và tên</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="full_name"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.full_name"  @input="updateFullName" v-model="user.full_name"></hnr-input>

              <span v-if="errors[0]" class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col cols="2" class="pa-1">
            <span> Slug </span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <hnr-input
                :text="user.slug"
                v-model="user.slug"
                @input="changeSlug"
            ></hnr-input>
          </v-col>
        </v-row>
        <v-row v-if="isCreate" class="justify-center mb-7">
          <v-col cols="2" class="pa-1">
            <span> Mật khẩu </span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="password"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input-password :text="user.password" @input="update" v-model="user.password"></hnr-input-password>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>

          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pa-1">
            <span> Email</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="email"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.email" @input="update" v-model="user.email"></hnr-input>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pa-1">
            <span> Năm sinh</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="year"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.year" @input="update" v-model="user.year"></hnr-input>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pa-1">
            <span> Môn học</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="subject"
                rules="required"
                v-slot="{ errors }"
            >
              <hnr-input :text="user.subject" @input="update" v-model="user.subject"></hnr-input>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="justify-center ">
          <v-col cols="2" class="pa-1">
            <span> Học vấn</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="education"
                rules="required"
                v-slot="{ errors }"
            >
              <ck-content
              :content="user.education"
              @getData="updateEduucation"
              v-model="user.education"
            ></ck-content>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="justify-center pt-7">
          <v-col cols="2" class="pa-1">
            <span> Thành tích</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="achievements"
                rules="required"
                v-slot="{ errors }"
            >
            <ck-content
              :content="user.achievements"
              @getData="updateAchievements"
              v-model="user.achievements"
            ></ck-content>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="justify-center pt-7">
          <v-col cols="2" class="pa-1">
            <span> Số điện thoại</span>
          </v-col>
          <v-col cols="10" class="pa-1">
            <ValidationProvider
                name="phone"
                rules="required"
                v-slot="{ errors }"
            >
              <v-text-field type="number" min="0"
                            outlined dense
                            :text="user.phone"
                            v-model="user.phone"
              ></v-text-field>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="!isCreate" class="align-center justify-center">
          <v-col cols="6" class="pa-3 d-flex justify-end">
            <v-btn color="primary" @click="updateUser">Cập nhật người dùng</v-btn>
          </v-col>
          <v-col cols="6" class="pa-3">
            <v-btn color="primary" @click="showDialog">Thay đổi mật khẩu</v-btn>
          </v-col>
        </v-row>

        <v-row v-else class="align-center justify-center">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" @click="handleSubmit(createUser)">Tạo gia sư</v-btn>
          </v-col>
        </v-row>

        <v-dialog
            v-model="dialog"
            max-width="600px"
            min-height="600px"
        >
          <v-card>
            <v-card-title class="justify-center">
              <span class="headline">Nhập mật khẩu mới cho người dùng</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="align-center justify-center ma-0">
                  <hnr-input-password :text="user.password" @input="update"
                                      v-model="user.password"></hnr-input-password>
                </v-row>
                <v-row class="align-center justify-center ma-0 pa-4">
                  <v-btn color="primary" @click="changePassword">Thay đổi mật khẩu</v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </ValidationObserver>
    </v-container>
  </v-app>
</template>

<script>
import HnrInput from '@/components/elements/hnr-input'
import HnrInputPassword from '@/components/elements/hnr-input-password'
import {mapGetters, mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {CREATE_TUTOR, CHANGE_PASSWORD_TUTOR} from "@/store/user.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
import {GET_LIST_GRADE} from "@/store/grade.module";
import {DETAIL_TUTOR, UPDATE_TUTOR} from "@/store/authority.module";
import CkContent from "@/components/ck-content.vue";

export default {
  data() {
    return {
      radioGroup: 2,
      userId: null,
      isCreate: true,
      user: {
        full_name: "",
        slug: "",
        phone: "",
        email: "",
        password: "",
        password_confirm: "",
        grade_name: "",
        subject: "",
        education: "",
        achievements: "", 
        year:"",
      },
      admin: 1,
      dialog: false
    };
  },
  components: {
    HnrInput,
    HnrInputPassword,
    CkContent
  },
  computed: {
    ...mapState({
      detailUser: state => state.user.user
    }),
    ...mapGetters({
      grades: "listGrade",
    })
  },
  watch: {
    detailUser: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.user = newVal;
          this.user.slug = this.sanitizeTitle(newVal.full_name);
        }
      }
    }
  },
  created() {
    this.userId = this.$route.params.id;
    if (!this.userId) {
      this.user.full_name = " ";
      this.user.password = "";
      this.user.authority = 1;
    }
    this.$store.dispatch(GET_LIST_GRADE);
  },
  mounted() {
    if (this.userId) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa thông tin người dùng"}]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_TUTOR, {user_id: this.userId}).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm người dùng"}]);
    }
  },
  methods: {
    updateFullName(value) {
      this.user.full_name = value;
      this.user.slug = this.sanitizeTitle(value);  // Automatically update slug
    },
    updateAchievements(val) {
      this.user.achievements = val;
    },
    updateEduucation(val) {
      this.user.education = val;
    },
    sanitizeTitle(title) {
      if (!title) {
        return null;
      }
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, '');
      // Change whitespace to "-"
      str = str.replace(/\s+/g, '-');

      str = str.replace(/\?|\(|\)|\/|!|@|#|\$|%|\^|&/g, '');
      return str;
    },
    onClick() {
    },
    update() {
      this.$emit("input");
    },
    changeSlug() {
      this.user.slugHasChanged = true;
    },
    showDialog() {
      this.user.password = "";
      this.dialog = true;
    },
    updateUser() {
      this.$store.dispatch(SET_LOADING, true);
      const grade_selected = this.grades?.length > 0 ? this.grades[0].name : '';
      let payload = {
        id: JSON.parse(this.userId),
        full_name: this.user.full_name,
        email: this.user.email,
        slug: this.user.slug,
        phone: this.user.phone,
        subject: this.user.subject,
        education: this.user.education,
        achievements: this.user.achievements, 
        year:this.user.year,
        grade: grade_selected,
        authority: 3,
      }
      this.$store.dispatch(UPDATE_TUTOR, payload).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Update người dùng thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Update người dùng thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Update người dùng thất bại!", {root: true});
      });
    },
    createUser() {
      this.$store.dispatch(SET_LOADING, true);
      const grade_selected = this.grades?.length > 0 ? this.grades[0].name : '';
      let payload = {
        full_name: this.user.full_name,
        slug: this.user.slug,
        email: this.user.email,
        phone: this.user.phone,
        password: this.user.password,
        password_confirm: this.user.password,
        subject: this.user.subject,
        education: this.user.education,
        achievements: this.user.achievements, 
        year:this.user.year,
        grade: grade_selected,
        authority: 3,
      }
      this.$store.dispatch(CREATE_TUTOR, payload).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Tạo người dùng thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if (data.errorCode == 425) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Tạo người dùng thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Tạo người dùng thất bại!", {root: true});
      });
    },
    changePassword() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(CHANGE_PASSWORD_TUTOR, {email: this.user.email, password: this.user.password}).then(data => {
        this.$store.dispatch(SET_LOADING, false);
        this.dialog = false;
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thay đổi mật khẩu thành công!", {root: true});
          this.$router.go(-1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Thay đổi mật khẩu thất bại!", {
              root: true,
            });
          }
        }
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
        this.$store.dispatch(ERROR, "Thay đổi mật khẩu thất bại!", {root: true});
      });
    }
  },
};
</script>

<style lang="scss">
</style>
